@font-face {
  font-family: "Caveat";
  src: url("../../assets/fonts/Caveat.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Museo-Sans";
  src: url("../../assets/fonts/MuseoSans_100.otf") format("opentype");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Museo-Sans";
  src: url("../../assets/fonts/MuseoSans_300.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Museo-Sans";
  src: url("../../assets/fonts/MuseoSans_500.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Museo-Sans";
  src: url("../../assets/fonts/MuseoSans_700.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Museo-Sans", sans-serif;
}

body {
  overflow-x: hidden!important;
  width: 100vw!important;
}

h1,
h2,
h3,
h4,
h5,
h5,
p,
a,
textarea,
address {
  margin: 0;
  font-family: "Museo-Sans", sans-serif;
  font-style: normal;
}

p {
  line-height: 21px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  cursor: pointer !important;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;

  /*
  == Blue Current:
  */
  --colors-BLUE: #0098db;
  --colors-LIGHTBLUE: #e4f3fa;
  --colors-GREY: #37424a;
  --colors-BLACK: #121212;
  --colors-YELLOW: #fee000;
  --colors-ORANGE: #ff9932;
  --colors-GREEN: #00ca8b;
  --colors-WHITE: #ffffff;
  --colors-PURPLE: #ff3699;
  --colors-GRADIENT: #e5f4fb;
  /*
  == Design:
  */
  --colors-LIGHTGREY: #fbfbfb;
  --colors-MEDIUMGREY: #cccccc;
  --colors-LIGHTWHITE: #f5f5f5;
  --colors-MEDIUMWHITE: #eeeeee;
  --colors-DARKWHITE: #dfdfdf;
  /*
  == Custom:
  */
  --colors-RED: #ef6b6b;

  /*
  == Breakpoints:
  */
  --breakpoints-xsmax: "575px";
  --breakpoints-smmin: "576px";
  --breakpoints-smmax: "767px";
  --breakpoints-mdmin: "768px";
  --breakpoints-mdmax: "991px";
  --breakpoints-lgmin: "992px";
  --breakpoints-lgmax: "1199px";
  --breakpoints-xlmin: "1200px";
  --breakpoints-xlmax: "1399px";
  --breakpoints-xxlmin: "1400px";
  --breakpoints-xxlmax: "1799px";
  --breakpoints-xxxlmin: "1800px";
}

.grecaptcha-badge {
  visibility: hidden;
}


.Hover:hover {
  cursor: pointer!important;
}

.Hover:hover.ContextMenu:hover {
  cursor: context-menu!important;
}

.Hover:hover.Text:hover {
  cursor: text!important;
}

.Disabled:hover {
  cursor: not-allowed !important;
}

.Default:hover {
  cursor: default!important;
}

.HoverText {
  cursor: text!important;
}

.DisableSelection,
.DisableSelection > * {
  user-select: none!important;
  -ms-user-select: none!important;
  -moz-user-select: none!important;
  -khtml-user-select: none!important;
  -webkit-user-select: none!important;
  -webkit-touch-callout: none!important;
}

/* Loader Spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Blurred Background workaround */
.BlurredBackground {
  background: rgba(255, 255, 255, 0.993);
}

@supports (backdrop-filter: blur(10px)) {
  .BlurredBackground {
    background: rgba(255, 255, 255, 0.85)!important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

/* HeaderLink hover */
.HeaderLink::after {
  transition: all 0.3s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 2px;
  top: 57.5px;
  content: "";
  background-color: var(--colors-BLUE);
}

.HeaderLink:hover::after {
  width: 100%;
}

input[type="range"] {
  -webkit-appearance: none;
  background: var(--colors-WHITE);
  background-image: linear-gradient(to left, var(--colors-PURPLE), var(--colors-PURPLE));
	background-repeat: no-repeat;
  border: 2px solid var(--colors-MEDIUMWHITE);
  border-radius: 10px;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 10px var(--colors-DARKWHITE);
  border: 2px solid var(--colors-DARKWHITE);
  height: 23px;
  width: 23px;
  border-radius: 25px;
  background: var(--colors-WHITE);
  cursor: pointer;
  margin-top: -4px;
}
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  height: 15px;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 1px 1px 10px var(--colors-DARKWHITE);
  border: 2px solid var(--colors-DARKWHITE);
  height: 23px;
  width: 23px;
  border-radius: 25px;
  background: var(--colors-WHITE);
  cursor: pointer;
  margin-top: -4px;
}
input[type="range"]::-moz-range-track {
  background: none;
  border: none;
  box-shadow: none;
  height: 15px;
}
input[type=range]:focus {
  outline: none;
}

.ScrollDownArrow:after {
  content: '';
  display: block;
  margin: 0;
  padding: 0;
  width: 8;
  height: 8px;
  border-top: 2px solid var(--colors-GREY);
  border-right: 2px solid var(--colors-GREY);
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.ScrollDownLine:after {
  -webkit-animation: scrollDownLine 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  -moz-animation: scrollDownLine 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  -o-animation: scrollDownLine 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  animation: scrollDownLine 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 60px;
  background: var(--colors-GREY);
  content: '';
}
@-webkit-keyframes scrollDownLine {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-moz-keyframes scrollDownLine {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-o-keyframes scrollDownLine {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@keyframes scrollDownLine {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

/* Text dots animation */
@keyframes dot1 { from { opacity: 0; } 25% { opacity: 1; } }
@keyframes dot2 { from { opacity: 0; } 50% { opacity: 1; } }
@keyframes dot3 { from { opacity: 0; } 75% { opacity: 1; } }
@-webkit-keyframes dot1 { from { opacity: 0; } 25% { opacity: 1; } }
@-webkit-keyframes dot2 { from { opacity: 0; } 50% { opacity: 1; } }
@-webkit-keyframes dot3 { from { opacity: 0; } 75% { opacity: 1; } }


.TextDots span {
  animation: dot1 1s infinite steps(1);
  -webkit-animation: dot1 1s infinite steps(1);
}

.TextDots span:first-child + span {
    animation-name: dot2;
    -webkit-animation-name: dot2;
}

.TextDots span:first-child + span + span {
    animation-name: dot3;
    -webkit-animation-name: dot3;
}

.TextComponent a {
  text-decoration: underline;
}
